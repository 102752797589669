import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';

import { useSearchParams } from "react-router-dom";
import { Box } from '@mui/system';
import { FormControl, FormHelperText, Input, InputLabel } from '@mui/material';

function submit(e, questions, emailAddress, name, phoneNumber, navigate, setSubmitEnabled) {
  e.preventDefault();

  // console.log(JSON.stringify(questions));

  let body = {
    "emailAddress": emailAddress,
    "name": name,
    "questions": questions
  };

  if(phoneNumber) {
    body.phoneNumber = phoneNumber;
  }

  // console.log(phoneNumber, JSON.stringify(body));

  setSubmitEnabled(false);

  fetch('https://short-fast-server.herokuapp.com/',
    {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'api-key': 'A3gD922HVaZzTJZqunaTR6OCVbIsDBvYk5DuE5Sl0FTom2pWa7S9biaaUNoDcuic',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(body), // body data type must match "Content-Type" header
    }
  )
    // .then(response => response.json())
    .then(response => {
      // console.log(response);
      if (response.status !== 200) {
        alert(response.message);
      } else {
        //alert('That you for submitting the answers');
        navigate('/d');
        // this.props.history.push('/editStory?t=' + response.editToken);
      }
    });
  // setSubmitEnabled(true);

}

function textChanged(id, name, value, state, funct) {
  state[id] = { key: name, value: value };
  funct(state);

  // console.log(JSON.stringify(state));
}

const handleSubmit = e => {
  e.preventDefault();
  console.log("submit");
};

export default function Answers(params) {
  const [question, setQuestions] = React.useState([]);
  const [submitEnabled, setSubmitEnabled] = React.useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const name = searchParams.get("name");
  const phoneNumber = searchParams.get("phoneNumber");
  const emailAddress = searchParams.get("email");
  const questions = searchParams.getAll("question");

  const navigate = useNavigate();

  return (
    emailAddress
      && questions
      && name ?
      <form id="myform" onSubmit = {(e) => submit(e, question, emailAddress, name, phoneNumber, navigate, setSubmitEnabled)} >
        <h1>Questions from {name}</h1>

        {questions.map((v, i) =>
          <div key={i} style={{ display: 'flex' }}>
            <TextField name={v} id={"" + i} label={v} variant="standard"
              required
              fullWidth
              // helperText='This answer is required'
              onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
              // sx={{ minWidth: 280, flexGrow: 1 }}
              onChange={(ii) => textChanged(i, v, ii.target.value, question, setQuestions)} />
          </div>)}

        <Button disabled={!submitEnabled} sx={{ mt: 2 }} variant="outlined"
          type='submit'
          // onSubmit={handleSubmit}
        // onClick={(i) => }
        >Submit</Button>

      </form>
      : <h4>URL is missing some parameters.</h4>
  );
}