import { Box, Card, CardContent, CardHeader, Divider, Link, Typography } from "@mui/material";
import { display } from "@mui/system";
import { Outlet } from "react-router-dom";


export default function App(params) {
  const subtitle = <ul><li>1,</li><li>2</li></ul>;

  return (
    <Card
      sx={{
        minWidth: 275,
        width: '80%',
        p: 0,
        m: 'auto',
        mt: 2,
        boxShadow: 3
      }}
    >
      <CardHeader sx={{ backgroundColor: 'primary.dark', color: 'white', p: 1 }}
        title='newcastle.ai'
        subheader={<div>Developed by <Link href="https://www.linkedin.com/in/joshualeask/">Josh Leask</Link></div>}
        subheaderTypographyProps={{
          gutterBottom: true
        }}
      />
      <CardContent>
        {/* <Divider sx={{ mb: 2 }} /> */}
        <Outlet />
      </CardContent>
    </Card>
  );
}