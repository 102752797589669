import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { Box } from '@mui/system';
import { Link, Tooltip, Typography } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QRCode from 'react-qr-code';
import * as svg from 'save-svg-as-png';

async function textChanged(id, name, value, state, funct) {
    var s = state.slice();
    s[id] = value;
    await funct(s);

    // console.log(name, JSON.stringify(state));
}

function getUrl(email, name, phoneNumber, questions) {
    var u = 'https://newcastle.ai/a?';
    // u = 'http://localhost:3000/a?';
    u += 'name=' + name + '&email=' + email;
    if (phoneNumber) {
        u += '&phoneNumber=' + phoneNumber;
    }

    questions.forEach(element => {
        u += '&question=' + element;
    });
    return encodeURI(u).replace('+', '%2b');
}

export default function Home(params) {
    const [questions, setQuestions] = React.useState(['Name', 'Email']);
    const [name, setName] = React.useState(""); //Josh Leask
    const [email, setEmail] = React.useState(""); //joshualeask@hotmail.com
    const [phone, setPhone] = React.useState(""); //+61409902889
    // console.log(questions);
    var u = getUrl(email, name, phone, questions);
    // console.log(u);
    return (
        <Box
            component="form"
            // sx={{
            //     '& .MuiTextField-root': { m: 1, width: '25ch' },
            // }}
            autoComplete="off"
        >
            <TextField
                {...params}
                inputProps={{
                    ...params.inputProps,
                    autoComplete: 'name',
                }}
                id='name'
                required
                sx={{ m: 1, minWidth: 280 }}
                label='Your Name'
                variant="standard"
                value={name}
                color='secondary'
                onChange={(v) => setName(v.target.value)}></TextField>
            <TextField
                required
                sx={{ m: 1, minWidth: 280 }} label='Email to receive answers' variant="standard" value={email}
                color='secondary'
                onChange={(v) => setEmail(v.target.value)}></TextField>
            {/* <Tooltip title="Phone to receive answers, inc country code">
                <TextField
                    sx={{ m: 1, minWidth: 280 }} label='Phone to receive answers, inc country code' variant="standard" value={phone}
                    color='secondary'
                    onChange={(v) => setPhone(v.target.value)}></TextField>
            </Tooltip> */}
            <Typography variant="h4" gutterBottom component="div" sx={{ m: 0, mt: 2 }}>
                Questions
            </Typography>
            {questions.map((v, i) =>
                <div key={i} style={{ display: 'flex', alignItems: 'center' }}>
                    <Button sx={{ mt: 2 }} onClick={() => {
                        var q = questions.slice();
                        q.splice(i, 1);
                        console.log(i, q);
                        setQuestions(q);
                    }}>
                        <Tooltip title="Remove question">
                            <IndeterminateCheckBoxOutlinedIcon color="primary" />
                        </Tooltip>
                    </Button>

                    <TextField name={"" + i} id={"" + i}
                        // label="Enter question here"
                        // required
                        variant="standard"
                        color='secondary'
                        sx={{ flexGrow: 1 }}
                        value={v}
                        onChange={async function (ii) {
                            textChanged(i, i, ii.target.value, questions, setQuestions);
                        }} />
                </div>)}
            <Button sx={{ m: 0.1, mb: 2 }} onClick={() => setQuestions([...questions, ''])}>
                <Tooltip title="Add question">
                    <AddBoxOutlinedIcon color="primary" />
                </Tooltip>
            </Button>

            <div>Copy this link and send it out:</div>
            <TextField value={u} sx={{ m: 1, width: '98%' }} />

            <CopyToClipboard text={u} sx={{ m: 1 }}
                onCopy={() => { }}>
                <Button>Copy URL to clipboard</Button>
            </CopyToClipboard>

            <div hidden={true}>
                <QRCode value={u} id='svg' />
            </div>
            <Button sx={{ m: 1 }} onClick={() => svg.saveSvgAsPng(document.getElementById("svg"), "QR_Code.png", { scale: 4 })}>
                Download QR Code
            </Button>

            <Link href={u} sx={{ m: 1 }}><Button>Try it out</Button></Link>
        </Box>
    );
}