import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { Box } from '@mui/system';
import { Container, Link, Tooltip, Typography } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QRCode from 'react-qr-code';
import * as svg from 'save-svg-as-png';
import { useNavigate, useSearchParams } from "react-router-dom";
import { ConstructionOutlined } from '@mui/icons-material';

const localhost = false;


function getDoneUrl(email, name, phoneNumber, profile) {
    var u = '/cs?done=true&';
    u += 'name=' + name + '&email=' + email;
    if (phoneNumber) {
        u += '&phoneNumber=' + phoneNumber;
    }

    if (profile) {
        u += '&profile=' + profile;
    }

    return encodeURI(u).replace('+', '%2b');
}

function submit(e, questions, emailAddress, name, phoneNumber, profile, navigate, setSubmitEnabled) {
    e.preventDefault();

    // console.log(JSON.stringify(questions));

    let body = {
        "emailAddress": emailAddress,
        "name": name,
        "questions": questions
    };

    if (phoneNumber) {
        body.phoneNumber = phoneNumber;
    }

    // console.log(phoneNumber, JSON.stringify(body));

    setSubmitEnabled(false);

    fetch('https://short-fast-server.herokuapp.com/',
        {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                'api-key': 'A3gD922HVaZzTJZqunaTR6OCVbIsDBvYk5DuE5Sl0FTom2pWa7S9biaaUNoDcuic',
            },
            redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer', // no-referrer, *client
            body: JSON.stringify(body), // body data type must match "Content-Type" header
        }
    )
        // .then(response => response.json())
        .then(response => {
            // console.log(response);
            if (response.status !== 200) {
                alert(response.message);
                setSubmitEnabled(true);
            } else {
                //alert('That you for submitting the answers');
                navigate(getDoneUrl(emailAddress, name, phoneNumber, profile));
                // this.props.history.push('/editStory?t=' + response.editToken);
            }
        });

    // setSubmitEnabled(true);
}

function answerChanged(id, name, value, state, funct) {
    state[id] = { key: name, value: value };
    funct(state);

    // console.log(JSON.stringify(state));
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

async function textChanged(id, name, value, state, funct) {
    var s = state.slice();
    s[id] = value;
    await funct(s);

    // console.log(name, JSON.stringify(state));
}

function getUrl(email, name, phoneNumber, profile, questions) {
    var u = 'https://newcastle.ai/cs?';
    if (localhost) {
        u = 'http://localhost:3000/cs?';
    }

    u += 'name=' + name + '&email=' + email;
    if (phoneNumber) {
        u += '&phoneNumber=' + phoneNumber;
    }

    if (profile) {
        u += '&profile=' + profile;
    }

    questions.forEach(element => {
        u += '&question=' + element;
    });
    return encodeURI(u).replace('+', '%2b');
}

export default function ContactSwap(params) {
    const [answers, setAnswers] = React.useState([]);
    const [submitEnabled, setSubmitEnabled] = React.useState(true);
    const [searchParams, setSearchParams] = useSearchParams();

    const nameParam = searchParams.get("name");
    const phoneParam = searchParams.get("phoneNumber");
    const emailParam = searchParams.get("email");
    const questionsParam = searchParams.getAll("question");
    const profileParam = searchParams.getAll("profile");

    const doneParam = searchParams.get("done");

    const navigate = useNavigate();

    const { height, width } = useWindowDimensions();
    const [questions, setQuestions] = React.useState(['Name', 'Email']);
    const [name, setName] = React.useState(""); //Josh Leask
    const [email, setEmail] = React.useState(""); //joshualeask@hotmail.com
    const [phone, setPhone] = React.useState(""); //+61409902889
    const [profile, setProfile] = React.useState(""); //https://www.linkedin.com/in/joshualeask/

    var u = getUrl(email, name, phone, profile, questions);

    var qrWidth = width * 0.65;
    if (qrWidth > 512) {
        qrWidth = 512;
    }

    if (doneParam) {
        const mailTo = 'mailto:' + emailParam;
        return <div>
            <Typography variant="h4" gutterBottom component="div" sx={{ m: 0, mt: 2 }}>Thank's for connecting</Typography>
            <Typography variant="h5" gutterBottom component="div" sx={{ m: 0, mt: 2 }}>{nameParam}</Typography>
            <Link href={mailTo} sx={{ m: 0 }}>
                <Typography variant="h5" gutterBottom component="div" sx={{ m: 0, mt: 2 }}>{emailParam}</Typography>
            </Link>
            <Link href={profileParam} sx={{ m: 0 }}>
                <Typography variant="h5" gutterBottom component="div" sx={{ m: 0, mt: 2 }}>{profileParam}</Typography>
            </Link>
        </div>
    }
    else if (questionsParam.length > 0) {
        return (
            emailParam
                && questionsParam
                && nameParam ?
                <form id="myform" onSubmit={(e) => submit(e, answers, emailParam, nameParam, phoneParam, profileParam, navigate, setSubmitEnabled)} >
                    <h2>Swap contact details with {nameParam}</h2>

                    {questionsParam.map((v, i) =>
                        <div key={i} style={{ display: 'flex' }}>
                            <TextField name={v} id={"" + i} label={v} variant="standard"
                                required
                                fullWidth
                                // helperText='This answer is required'
                                onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
                                // sx={{ minWidth: 280, flexGrow: 1 }}
                                onChange={(ii) => answerChanged(i, v, ii.target.value, answers, setAnswers)} />
                        </div>)}

                    <Button disabled={!submitEnabled} sx={{ mt: 2 }} variant="outlined"
                        type='submit'
                    // onSubmit={handleSubmit}
                    // onClick={(i) => }
                    >Submit</Button>

                </form>
                : <h4>URL is missing some parameters.</h4>
        );
    }
    else {
        // console.log(u);
        return (
            <Box
                component="form"
                // sx={{
                //     '& .MuiTextField-root': { m: 1, width: '25ch' },
                // }}
                autoComplete="off"
            >
                <TextField
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'name',
                    }}
                    id='name'
                    required
                    sx={{ m: 1, minWidth: 280 }}
                    label='Your Name'
                    variant="standard"
                    value={name}
                    color='secondary'
                    onChange={(v) => setName(v.target.value)}></TextField>
                <TextField
                    required
                    sx={{ m: 1, minWidth: 280 }} label='Your Email' variant="standard" value={email}
                    color='secondary'
                    onChange={(v) => setEmail(v.target.value)}></TextField>
                {/* <Tooltip title="Phone to receive answers, inc country code"> */}
                {/* <TextField
                    sx={{ m: 1, minWidth: 280 }} label='Phone to receive answers, inc country code' variant="standard" value={phone}
                    color='secondary'
                    onChange={(v) => setPhone(v.target.value)}></TextField> */}
                {/* </Tooltip> */}
                <TextField
                    sx={{ m: 1, minWidth: 280 }} label='Profile link, eg. Linkedin' variant="standard" value={profile}
                    color='secondary'
                    onChange={(v) => setProfile(v.target.value)}></TextField>


                <Typography variant="h4" gutterBottom component="div" sx={{ m: 0, mt: 2 }}>
                    Contact details to collect
                </Typography>
                {questions.map((v, i) =>
                    <div key={i} style={{ display: 'flex', alignItems: 'center' }}>
                        <Button sx={{ mt: 2 }} onClick={() => {
                            var q = questions.slice();
                            q.splice(i, 1);
                            console.log(i, q);
                            setQuestions(q);
                        }}>
                            <Tooltip title="Remove question">
                                <IndeterminateCheckBoxOutlinedIcon color="primary" />
                            </Tooltip>
                        </Button>

                        <TextField name={"" + i} id={"" + i}
                            // label="Enter question here"
                            // required
                            variant="standard"
                            color='secondary'
                            sx={{ flexGrow: 1 }}
                            value={v}
                            onChange={async function (ii) {
                                textChanged(i, i, ii.target.value, questions, setQuestions);
                            }} />
                    </div>)}
                <Button sx={{ m: 0.1, mb: 2 }} onClick={() => setQuestions([...questions, ''])}>
                    <Tooltip title="Add question">
                        <AddBoxOutlinedIcon color="primary" />
                    </Tooltip>
                </Button>

                <div>Copy this link and send it out:</div>
                <TextField value={u} sx={{ m: 1, width: '98%' }} />

                <Container disableGutters sx={{ m: 2 }}>Show this QR code to swap contact details</Container>
                <Container disableGutters sx={{ m: 2 }}>
                    <QRCode value={u} id='svg' size={qrWidth} level='H' />
                </Container>
                <Button sx={{ m: 1 }} onClick={() => svg.saveSvgAsPng(document.getElementById("svg"), "QR_Code.png", { scale: 4 })}>
                    Download QR Code
                </Button>


                <CopyToClipboard text={u} sx={{ m: 1 }}
                    onCopy={() => { }}>
                    <Button>Copy URL to clipboard</Button>
                </CopyToClipboard>

                <Link href={u} sx={{ m: 1 }}><Button>Try it out</Button></Link>
            </Box>
        );
    }
}